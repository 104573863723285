<template>
  <div class="subject_warp">
    <div class="form">
      <div class="date">
        <span>
          教师组卷统计
        </span>
        <div class="teacher">
          <el-select v-model="edu_usr_id"
                     v-if="$parent.home_id == 2 || $parent.home_id == 1"
                     style="width:100%"
                     @change="clear"
                     clearable
                     filterable
                     placeholder="请选择">
            <el-option v-for="item in $parent.teacherList"
                       :key="item.edu_user_id"
                       :label="item.edu_user_name"
                       :value="item.edu_user_id">
            </el-option>
          </el-select>
        </div>
        <div class="checkDate">
          <el-date-picker v-model="date"
                          style="margin:10px 0"
                          @focus="$forbid"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div class="subject">
        <el-date-picker v-model="date"
                        @focus="$forbid"
                        style="margin:10px 0"
                        @change="clear"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>
    <div id="myChartStatics"
         :style="{width: '100%', height: '40vh'}"></div>
  </div>
</template>

<script>
import { frontDay, frontNowDay } from '@/utils/date.js'
export default {
  data () {
    return {
      subjectList: [],
      teacher: '',
      list: [],
      edu_usr_id: '',
      // date: [frontDay(7, false), frontNowDay(0, false)],
      date: []
    }
  },
  methods: {
    clear () {
      let myChart = this.$echarts.init(document.getElementById('myChartStatics'))
      myChart.dispose()
      this.getList()
    },
    drawLine () {
      var myChart = this.$echarts.init(document.getElementById('myChartStatics'));
      // 绘制图表
      myChart.setOption({
        graphic: {
          type: "text",
          left: "32%",
          top: "48%",
          style: {
            text: this.count || '',
            textAlign: "center",
            fill: "#333",
            fontSize: 20,
            fontWeight: 700
          }
        },
        color: ['#3F9EFF', '#41D7D7'],
        tooltip: {
          trigger: 'item'
        },
        grid: {
          left: '1%',
        },
        // 配置距离左边，上边的距离
        legend: {
          orient: 'vertical',
          left: '65%',
          top: '40%'
        },
        series: [
          {
            name: '',
            // name: 'Access From',
            type: 'pie',
            center: ['35%', '50%'],
            radius: ['40%', '60%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.list
          }
        ]
      })
    },
    getList () {
      let params = {
        school_id: this.$parent.school_id,
        edu_usr_id: this.edu_usr_id,
      }
      if (this.date) {
        params.start_time = this.date[0]
        params.end_time = this.date[1]
      }
      this.$http({
        url: '/api/v1/main/get_teacher_paper',
        method: 'get',
        params
      }).then(res => {
        let list = []
        let count = 0
        res.data.list.forEach(item => {
          count += Number(item.count)
          let data = {
            value: Number(item.count),
            name: item.module_name
          }
          list.push(data)
        })
        this.count = count
        this.list = list
        this.$nextTick(() => {
          this.drawLine()
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .checkDate {
    display: none !important;
  }
  .isActive {
    border: 1px solid #3f9eff !important;
    color: #3f9eff !important;
  }
  .subject_warp {
    background: white;
    padding: 10px;
    margin: 1%;
  }
  .subject_list {
    border: 1px solid #d9d9d9;
    display: inline-block;
    span {
      display: inline-block;
      border-right: 1px solid #d9d9d9;
      padding: 5px 10px;
      cursor: pointer;
      color: #000000;
    }
  }
  .form {
    display: flex;
    .date {
      flex: 1;
      display: flex;
      align-items: center;
      span {
        width: 120px;
        font-weight: bold;
        color: rgba(8, 8, 8, 0.85);
        font-size: 16px;
      }
      div {
        display: inline-block;
        width: 110px;
      }
    }
    .subject {
      display: flex;
    }
  }
  ::v-deep.el-range-editor.el-input__inner {
    width: 230px !important;
  }
}

@media screen and (max-width: 1200px) {
  .subject_warp {
    overflow: hidden;
    background: white;
    border-radius: 5px;
    .form {
      position: relative;
      .date {
        span {
          display: block;
          width: 100%;
          font-size: 16px;
          font-weight: bold;
          color: rgba(8, 8, 8, 0.85);
          text-align: center;
          margin: 10px 0;
        }
      }
      .teacher {
        width: 100px;
        margin-left: 10px;
        display: inline-block;
      }
      .checkDate {
        margin-left: 20px;
        display: inline-block;
        .el-date-editor {
          width: 220px;
        }
      }
      .subject {
        display: none;
      }
    }
  }
}
</style>